import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, InlineSpace, PageHeader, Row } from '@smart-kasa/ui';

import { OfflineEventsChart } from './components/OfflineEventsChart';
import { ShiftStats } from './components/ShiftStats';
import { BillingStats } from './components/BillingStats';
import { FeedbackWidget } from './components/FeedbackWidget';

export const Dashboard = () => (
  <Fragment>
    <PageHeader>
      <FormattedMessage id="dashboard.title" />
    </PageHeader>

    <OfflineEventsChart />

    <InlineSpace direction="vertical" size={20} />

    <FeedbackWidget />

    <InlineSpace direction="vertical" size={20} />

    <Row gutter={10}>
      <Col span={10}>
        <ShiftStats />
      </Col>
      <Col span={14}>
        <BillingStats />
      </Col>
    </Row>
  </Fragment>
);
