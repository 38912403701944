import { useParams } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { Avatar, Card, Col, Preloader, Row } from '@smart-kasa/ui';

import { Description } from 'components';
import { formattedPhone } from 'utils/formattedPhone';
import { useFindUserQuery } from 'services/api/core/user/api';

import { UserStatus } from '../../components/UserStatus';
import styles from './UserShow.module.scss';

export const UserShow = () => {
  const { id } = useParams();
  const { user } = useFindUserQuery(Number(id), {
    selectFromResult: ({ data: response }) => ({ user: response?.data }),
  });

  if (!user) return <Preloader />;

  return (
    <Card>
      <Row gutter={20} className={styles.row}>
        <Col span={3} className={styles.avatar}>
          <Avatar src={user.avatar?.url} size="xx-large" />
        </Col>
        <Col span={5}>
          <Description label="ПІБ">{user.name}</Description>
          <Description label="Номер телефону">{formattedPhone(user.phoneNumber)}</Description>
          <Description label="Електронна пошта">{user.email}</Description>
        </Col>
        <Col span={4}>
          <Description label="Статус">
            <UserStatus state={user.state} />
          </Description>
          <Description label="Дата реєстрації">
            <FormattedDate value={user.createdAt} />
          </Description>
          <Description label="Дата оновлення">
            <FormattedDate value={user.updatedAt} />
          </Description>
        </Col>
        <Col span={12}>
          <Description label="Код доступу власника">
            <div className={styles.code}>{user.pinCode}</div>
          </Description>
        </Col>
      </Row>
    </Card>
  );
};
