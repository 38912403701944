import { Fragment } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ButtonIcon,
  Card,
  CardTitle,
  DataTable,
  Hint,
  IDataTableColumn,
  Icon,
  Pagination,
  Paragraph,
  Strong,
  Tag,
} from '@smart-kasa/ui';

import { usePagination } from 'hooks/usePagination';
import { useQuery } from 'hooks/useQuery';

import { useGetShiftsQuery } from 'services/api/core/shifts/api';
import type { IShift } from 'services/api/core/shifts/types';
import { ShiftState } from 'services/api/core/shifts/enums';
import { ShiftStatus } from 'features/shifts/components/ShiftStatus';
import { ShiftFilter } from '../../components/ShiftFilter';

export const ShiftList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { query, push: setQuery } = useQuery();
  const { onPageChange } = usePagination();
  const { data: shifts, isLoading } = useGetShiftsQuery({
    ...query,
    ownerId: id,
    sortColumn: 'opened_at',
    sortMode: 'desc',
  });

  const columns: IDataTableColumn<IShift>[] = [
    {
      key: 'fiscalNumber',
      title: formatMessage({ id: 'pos.shift.fiscal-number' }),
      render: (fiscalNumber, { registrarFiscalNumber }) => (
        <Fragment>
          {fiscalNumber ? (
            <Fragment>
              <Tag color="violet">Фіскальна зміна</Tag>
              <Hint>
                {fiscalNumber} / {registrarFiscalNumber}
              </Hint>
            </Fragment>
          ) : (
            <Tag color="green">Торгова зміна</Tag>
          )}
        </Fragment>
      ),
    },
    {
      key: 'state',
      title: formatMessage({ id: 'pos.shift.state' }),
      render: (state: ShiftState) => <ShiftStatus state={state} />,
    },
    {
      key: 'openedAt',
      title: formatMessage({ id: 'pos.shift.opened' }),
      render: (openedAt, { openedBy }) => (
        <Fragment>
          {openedBy}

          <Hint margin={0}>
            {openedAt && <FormattedDate value={openedAt} dateStyle="short" timeStyle="short" />}
          </Hint>
        </Fragment>
      ),
    },
    {
      key: 'closedAt',
      title: formatMessage({ id: 'pos.shift.closed' }),
      render: (closedAt, { closedBy }) => (
        <Fragment>
          {closedBy || '-'}

          <Hint margin={0}>
            {closedAt && <FormattedDate value={closedAt} dateStyle="short" timeStyle="short" />}
          </Hint>
        </Fragment>
      ),
    },
    {
      key: 'deviceUid',
      title: formatMessage({ id: 'pos.shift.device' }),
      render: (deviceUid, { shop }) => (
        <Fragment>
          <Strong>{deviceUid}</Strong>
          {shop && (
            <Hint margin={0}>
              <Paragraph>
                #{shop.id} - {shop.title}
              </Paragraph>
              {shop.address?.content}
            </Hint>
          )}
        </Fragment>
      ),
    },
    {
      key: 'id',
      render: () => (
        <ButtonIcon>
          <Icon name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Fragment>
      <ShiftFilter defaultValues={query} onSubmit={setQuery} active={true} />

      <Card>
        <CardTitle>Перелік торгових та фіскальних змін</CardTitle>
        <DataTable
          rowIdKey="id"
          data={shifts?.data}
          columns={columns}
          isLoading={isLoading}
          emptyText="Список порожній"
          onRowClick={({ id }) => navigate(`/shifts/${id}`)}
        />

        <Pagination
          totalItems={shifts?.meta?.totalCount}
          currentPage={shifts?.meta?.currentPage}
          perPage={shifts?.meta?.limitValue}
          onPageChange={onPageChange}
        />
      </Card>
    </Fragment>
  );
};
