import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Icon } from '@smart-kasa/ui';

import { Field, Form } from 'components';
import styles from './ChargeForm.module.scss';

interface IChargeForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

export const ChargeForm: FC<IChargeForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Form<any> onSubmit={onSubmit} form={{ defaultValues }} className={styles.form}>
      <Field.Number
        name="amount"
        label={formatMessage({ id: 'wallet.transaction.amount' })}
        allowNegative={true}
        allowDecimal={true}
        precision={2}
        prefixIcon={<Icon name="coins" size="large" />}
        description="Можна вказувати від'ємне значення суми. Якщо сума рівна нулю - відбудеться перерахунок по
        існуючим інвойсам"
      />

      <Button type="submit" color="dark" className={styles.button}>
        {formatMessage({ id: 'buttons.save' })}
      </Button>
    </Form>
  );
};
