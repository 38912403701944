import { coreApi } from '../api';
import type { Pagination, CoreResponse } from 'types/api';

import type { FeedbackFilterParams, IFeedbackMessage } from './types';

export const feedbackApi = coreApi
  .enhanceEndpoints({ addTagTypes: ['FeedbackMessage'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMessages: builder.query<
        CoreResponse<IFeedbackMessage[]>,
        Pagination<IFeedbackMessage> & FeedbackFilterParams
      >({
        query: (params?) => ({
          url: 'core/manage/feedbacks/messages',
          params,
        }),
        providesTags: ['FeedbackMessage'],
      }),
      findMessage: builder.query<CoreResponse<IFeedbackMessage>, number>({
        query: (id) => ({
          url: `core/manage/feedbacks/messages/${id}`,
        }),
        providesTags: ['FeedbackMessage'],
      }),
      updateMessage: builder.mutation<void, Pick<IFeedbackMessage, 'id' | 'state'>>({
        query: ({ id, ...body }) => ({
          url: `core/manage/feedbacks/messages/${id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['FeedbackMessage'],
      }),
      deleteMessage: builder.mutation<{ success: boolean; id: number }, number>({
        query: (id) => ({
          url: `core/manage/feedbacks/messages/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['FeedbackMessage'],
      }),
    }),
  });

export const {
  useGetMessagesQuery,
  useFindMessageQuery,
  useUpdateMessageMutation,
  useDeleteMessageMutation,
} = feedbackApi;
