import { PieChart as Chart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { PieLabel } from './PieLabel';

export type PieChartProps = {
  width?: string | number;
  height?: string | number;
  colors?: Array<string>;
  data?: any[];
  dataKey?: string;
};

export const PieChart = ({
  width,
  height = 300,
  colors = ['#29cd6b', '#6d2ad8', '#57b7da', '#444674', '#f2b711'],
  data = [],
  dataKey = 'value',
  ...rest
}: PieChartProps) => (
  <ResponsiveContainer width={width} height={height}>
    <Chart {...rest}>
      <defs>
        {colors.map((color, index) => (
          <linearGradient
            key={`bar-color-${index}`}
            id={`pie-gradient-${index}`}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="5%" stopColor={color} stopOpacity={1} />
            <stop offset="95%" stopColor={color} stopOpacity={0.75} />
          </linearGradient>
        ))}
      </defs>

      <Pie data={data} labelLine={false} label={PieLabel} fill="#8884d8" dataKey={dataKey}>
        {data.map((_entry, index) => (
          <Cell key={`cell-${index}`} fill={`url(#pie-gradient-${index})`} />
        ))}
      </Pie>
      <Tooltip
        wrapperStyle={{ outline: 'none' }}
        contentStyle={{
          fontSize: 12,
          border: '1px solid rgb(219, 214, 225)',
          borderRadius: 6,
          boxShadow: '-3px 4px 8px -8px rgba(34, 60, 80, .2)',
        }}
      />
      <Legend layout="radial" align="center" iconType="circle" wrapperStyle={{ fontSize: 12 }} />
    </Chart>
  </ResponsiveContainer>
);
