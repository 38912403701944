import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import { Col, Page, Preloader, Row } from '@smart-kasa/ui';

import { BackLink, Caption } from 'components';
import useToast from 'hooks/useToast';

import { useFindMessageQuery, useUpdateMessageMutation } from 'services/api/core/feedback/api';
import { FeedbackMessageForm } from 'features/feedbacks/components/FeedbackMessageForm';

export const FeedbackShow = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { toast } = useToast();
  const { feedback } = useFindMessageQuery(Number(id), {
    selectFromResult: ({ data: response }) => ({ feedback: response?.data }),
  });
  const [update] = useUpdateMessageMutation();
  const onStateChange = useCallback(
    async ({ state }) => {
      const result = await update({ id: Number(id), state });

      if ('error' in result) {
        return toast.error(formatMessage({ id: 'toast.error' }));
      }

      toast.success(formatMessage({ id: 'toast.success' }));
    },
    [id, update, toast, formatMessage]
  );

  if (!feedback) return <Preloader />;

  return (
    <Page
      title={
        <BackLink>
          {feedback.name} / Звернення #{feedback.id}
        </BackLink>
      }
    >
      <Row gutter={10}>
        <Col span={12}>
          <Caption name={formatMessage({ id: 'feedback.name' })}>{feedback.name}</Caption>
          <Caption name={formatMessage({ id: 'feedback.phone' })}>{feedback.phoneNumber}</Caption>
          <Caption name={formatMessage({ id: 'feedback.email' })}>{feedback.email}</Caption>
          <Caption name={formatMessage({ id: 'feedback.createdAt' })}>
            <FormattedDate value={feedback.createdAt} dateStyle="short" timeStyle="short" />
          </Caption>
        </Col>
        <Col span={12}>
          <Caption name={formatMessage({ id: 'feedback.state' })}>
            <FeedbackMessageForm
              defaultValues={{ state: feedback.state }}
              onSubmit={onStateChange}
            />
          </Caption>
          {feedback.serialNumber && (
            <Caption name={formatMessage({ id: 'feedback.serial' })}>
              {feedback.serialNumber}
            </Caption>
          )}
          <Caption name={formatMessage({ id: 'feedback.content' })}>{feedback.content}</Caption>
        </Col>
      </Row>
    </Page>
  );
};
