import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import {
  ButtonIcon,
  DataTable,
  Hint,
  IDataTableColumn,
  Icon,
  Page,
  Pagination,
} from '@smart-kasa/ui';

import { useQuery } from 'hooks/useQuery';
import { usePagination } from 'hooks/usePagination';

import { useGetMessagesQuery } from 'services/api/core/feedback/api';
import { IFeedbackMessage } from 'services/api/core/feedback/types';
import { FeedbackMessageStatus } from 'features/feedbacks/components/FeedbackMessageStatus';

export const FeedbackList = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { query } = useQuery();
  const { onPageChange, onSortChange } = usePagination();
  const { data: messages, isLoading } = useGetMessagesQuery(query);

  const columns: IDataTableColumn<IFeedbackMessage>[] = [
    {
      key: 'id',
      title: formatMessage({ id: 'feedback.id' }),
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'name',
      title: formatMessage({ id: 'feedback.name' }),
    },
    {
      key: 'state',
      title: formatMessage({ id: 'feedback.state' }),
      render: (state) => <FeedbackMessageStatus state={state} />,
    },
    {
      key: 'email',
      title: formatMessage({ id: 'feedback.email' }),
      render: (email, { phoneNumber }) => (
        <Fragment>
          {phoneNumber}
          <Hint margin={0}>{email}</Hint>
        </Fragment>
      ),
    },
    {
      key: 'serialNumber',
      title: formatMessage({ id: 'feedback.serial' }),
    },
    {
      key: 'createdAt',
      title: formatMessage({ id: 'feedback.createdAt' }),
      render: (createdAt) => (
        <FormattedDate value={createdAt} dateStyle="short" timeStyle="short" />
      ),
    },
    {
      key: 'id',
      render: (id) => (
        <ButtonIcon onClick={() => navigate(`${id}`)}>
          <Icon name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Page title="Звернення">
      <DataTable
        rowIdKey="id"
        data={messages?.data}
        columns={columns}
        isLoading={isLoading}
        onRowClick={({ id }) => navigate(`${id}`)}
        onSortChange={onSortChange}
      />

      <Pagination
        totalItems={messages?.meta?.totalCount}
        currentPage={messages?.meta?.currentPage}
        perPage={messages?.meta?.limitValue}
        onPageChange={onPageChange}
      />
    </Page>
  );
};
