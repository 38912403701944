import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Field } from 'components';

import { FeedbackMessageState } from 'services/api/core/feedback/enums';

interface FeedbackMessageFormProps {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

const STATUSES = [
  {
    value: FeedbackMessageState.PENDING,
    label: 'Очікує на відповідь',
  },
  {
    value: FeedbackMessageState.ASSIGNED,
    label: 'В обробці',
  },
  {
    value: FeedbackMessageState.RESOLVED,
    label: 'Заявка виконана',
  },
  {
    value: FeedbackMessageState.REJECTED,
    label: 'Заявка відхилена',
  },
  {
    value: FeedbackMessageState.NOT_RESPONDING,
    label: 'Не відповідає',
  },
];

export const FeedbackMessageForm: FC<FeedbackMessageFormProps> = ({ defaultValues, onSubmit }) => {
  const form = useForm({ defaultValues });
  const handleSubmit = form.handleSubmit(onSubmit);

  useEffect(() => {
    const subscription = form.watch(handleSubmit);

    return () => subscription.unsubscribe();
  }, [form, handleSubmit]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <Field.Select name="state" placeholder="Оберіть статус" options={STATUSES} />
      </form>
    </FormProvider>
  );
};
