import { Tag } from '@smart-kasa/ui';

import { FeedbackMessageState } from 'services/api/core/feedback/enums';

const STATE_NAME: Record<FeedbackMessageState, { name: string; color: string }> = {
  [FeedbackMessageState.PENDING]: {
    name: 'Очікує на відповідь',
    color: 'grey',
  },
  [FeedbackMessageState.ASSIGNED]: {
    name: 'В обробці',
    color: 'blue',
  },
  [FeedbackMessageState.RESOLVED]: {
    name: 'Заявка виконана',
    color: 'green',
  },
  [FeedbackMessageState.REJECTED]: {
    name: 'Заявка відхилена',
    color: 'orange',
  },
  [FeedbackMessageState.NOT_RESPONDING]: {
    name: 'Не відповідає',
    color: 'yellow',
  },
};

export type FeedbackMessageStatusProps = {
  state: FeedbackMessageState;
  children?: never;
};

export const FeedbackMessageStatus = ({ state }) => {
  const status = STATE_NAME[state];

  return status ? <Tag color={status.color}>{status.name}</Tag> : <>-</>;
};
