import { useIntl } from 'react-intl';
import {
  AreaChart as Chart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

export type AreaChartProps = {
  width?: string | number;
  height?: string | number;
  xAxis?: string;
  yAxis?: string;
  color?: string;
  data?: any[];
};

export const AreaChart = ({
  width,
  height = 300,
  xAxis = 'date',
  yAxis = 'value',
  color = '#6D2AD8',
  data = [],
  ...rest
}: AreaChartProps) => {
  const { formatDate } = useIntl();

  return (
    <ResponsiveContainer width={width} height={height}>
      <Chart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
        {...rest}
      >
        <defs>
          <linearGradient id="area-gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={1} />
            <stop offset="95%" stopColor={color} stopOpacity={0.5} />
          </linearGradient>
        </defs>

        <CartesianGrid stroke="#f9f7fa" vertical={false} />
        <XAxis
          tickFormatter={(date) =>
            formatDate(date, { timeStyle: 'short', timeZone: 'Europe/Kyiv' })
          }
          dataKey={xAxis}
          tick={{ fontSize: 10 }}
          stroke="#827392"
        />
        <YAxis tick={{ fontSize: 10 }} width={40} stroke="#827392" />
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          contentStyle={{
            fontSize: 12,
            border: '1px solid rgb(219, 214, 225)',
            borderRadius: 6,
            boxShadow: '-3px 4px 8px -8px rgba(34, 60, 80, .2)',
          }}
          labelFormatter={(date) =>
            formatDate(date, { dateStyle: 'short', timeStyle: 'short', timeZone: 'Europe/Kyiv' })
          }
        />
        <Area
          dataKey={yAxis}
          fillOpacity={1}
          stroke={color}
          fill="url(#area-gradient)"
          dot={{ r: 1 }}
        />
      </Chart>
    </ResponsiveContainer>
  );
};
