import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import {
  ButtonIcon,
  ButtonText,
  Card,
  CardTitle,
  DataTable,
  Hint,
  IDataTableColumn,
  Icon,
  Strong,
} from '@smart-kasa/ui';

import { useGetMessagesQuery } from 'services/api/core/feedback/api';
import { IFeedbackMessage } from 'services/api/core/feedback/types';

import { FeedbackMessageStatus } from 'features/feedbacks/components/FeedbackMessageStatus';
import styles from './FeedbackWidget.module.scss';

export const FeedbackWidget = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { data: messages, isLoading } = useGetMessagesQuery({ perPage: '8' });

  const columns: IDataTableColumn<IFeedbackMessage>[] = [
    {
      key: 'id',
      title: formatMessage({ id: 'feedback.id' }),
      render: (id, { name }) => (
        <Fragment>
          <Hint margin={0}>#{id}</Hint>
          <Strong>{name}</Strong>
        </Fragment>
      ),
    },
    {
      key: 'serialNumber',
      title: formatMessage({ id: 'feedback.serial' }),
    },
    {
      key: 'state',
      title: formatMessage({ id: 'feedback.state' }),
      render: (state) => <FeedbackMessageStatus state={state} />,
    },
    {
      key: 'createdAt',
      title: formatMessage({ id: 'feedback.createdAt' }),
      render: (createdAt) => (
        <FormattedDate value={createdAt} dateStyle="short" timeStyle="short" />
      ),
    },
    {
      key: 'id',
      render: (id) => (
        <ButtonIcon onClick={() => navigate(`${id}`)}>
          <Icon name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Card>
      <CardTitle
        actions={
          <ButtonText onClick={() => navigate('/messages')}>
            <FormattedMessage id="dashboard.feedbacks.all" />
          </ButtonText>
        }
      >
        <FormattedMessage id="dashboard.feedbacks" />
      </CardTitle>

      <DataTable
        rowIdKey="id"
        data={messages?.data}
        columns={columns}
        isLoading={isLoading}
        onRowClick={({ id }) => navigate(`/messages/${id}`)}
        className={styles.table}
      />
    </Card>
  );
};
