import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Card, CardTitle, Preloader } from '@smart-kasa/ui';

import { AreaChart, ImageIcon } from 'components';
import { useGetOfflineStatsQuery } from 'services/api/core/stats/api';
import Sentry from 'assets/services/sentry.svg';

const TIME_INTERVAL = 5;

export const OfflineEventsChart = () => {
  const { data: stats, isLoading } = useGetOfflineStatsQuery();
  const events = useMemo(() => {
    const series: { date: string; value: number }[] = [];
    const source = new Map(
      stats?.data.map((item) => [new Date(item.date).toISOString(), item.value])
    );

    const endTime = new Date();
    const startTime = new Date(
      endTime.getFullYear(),
      endTime.getMonth(),
      endTime.getDate() - 1,
      endTime.getHours(),
      Math.round(endTime.getMinutes() / TIME_INTERVAL) * TIME_INTERVAL
    );

    for (let time = startTime; time < endTime; time.setMinutes(time.getMinutes() + TIME_INTERVAL)) {
      const isoTime = time.toISOString();

      series.push({
        date: isoTime,
        value: source.get(isoTime) || 0,
      });
    }

    return series;
  }, [stats]);

  const openSentry = () => {
    window.open('https://smartkasa.sentry.io/discover/results', '_blank', 'noreferrer');
  };

  return (
    <Card>
      <CardTitle
        actions={
          <Button
            color="dark"
            size="small"
            rightIcon={<ImageIcon src={Sentry} alt="Sentry" size={12} />}
            onClick={openSentry}
          >
            Sentry events
          </Button>
        }
      >
        <FormattedMessage id="dashboard.connection" />
      </CardTitle>

      {isLoading ? (
        <Preloader />
      ) : (
        <AreaChart data={events} height={150} xAxis="date" yAxis="value" />
      )}
    </Card>
  );
};
