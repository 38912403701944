import { Fragment } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Hint, PageHeader, Preloader, Strong } from '@smart-kasa/ui';

import { BackRouteLink } from 'components';
import { formattedPhone } from 'utils/formattedPhone';

import { useFindUserQuery } from 'services/api/core/user/api';
import { Navigation } from 'features/users/components/Navigation';
import styles from './UserLayout.module.scss';

export const UserLayout = () => {
  const { id } = useParams();
  const { data: record } = useFindUserQuery(Number(id));
  const user = record?.data;

  if (!user) return <Preloader />;

  return (
    <Fragment>
      <PageHeader>
        <BackRouteLink to="/users" className={styles.link}>
          <div>
            <Strong>{user.name}</Strong>
            <Hint margin={0}>{formattedPhone(user.phoneNumber)}</Hint>
          </div>
        </BackRouteLink>
      </PageHeader>

      <Navigation user={user} />

      <Outlet />
    </Fragment>
  );
};
